export default function AboutMe() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="about--section--img">
          <img src="./img/sahib_pic_1.png" alt="Self portrait" />
        </div>
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">About Me</p>
            <p className="hero--section-description">
            I'm a passionate computer engineering student based in the GTA, currently studying at the University of Guelph. With a keen focus on enhancing my software engineering skills, I consistently tackle projects and optimize my daily workflow for added convenience. I have an open interest in Machine Learning solely because of its potential for impact on the world! Beyond the development world, I enjoy the simple joys of life with my family and dog. I also love watching sports and playing video games! My vision in software development envisions projects that prioritize user convenience, striving to alleviate manual burdens and enhance the overall human experience through seamless automation.
            </p>
          </div>
        </div>
      </section>
    );
  }